.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
}
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border: 0;
}

.carousel-indicators button {
    background-color: #696969;
    border-radius: 40%;
    width: 20px !important;
    height: 20px !important;
}

.carousel-indicators button.active {
    background-color: #ffffff;
}

.background {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: auto;
}

.foreground {
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.text-body {
    margin: 10px;
}

.table {
    --bs-table-striped-bg: rgba(0,0,0,0.1);
}

.contact-col-left {
    text-align: right;
}

.email {
    font-size: small;
}

.full-fit-image {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.contact-logo {
    width: 100px;
}

p, em, li {
    font-size: medium;
}

td {
    font-size: medium;
}

@media only screen and (min-width: 320px) {
    .contact-logo {
        width: 200px;
    }

    .email {
        font-size: large;
    }
}

@media only screen and (min-width: 800px) {
    .foreground {
        width: 800px;
    }

    p, em, li, td {
        font-size: large;
    }
}